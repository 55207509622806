#root {
  height: 100vh;
  width: 100vw;
  display: grid;
  background: #fff;
}

/* scrollbar */
* {
  /* IE */
  scrollbar-face-color: #596975;
  scrollbar-shadow-color: #596975;
  scrollbar-darkshadow-color: #596975;
  scrollbar-track-color: rgba(217, 228, 235, 0.75);
  scrollbar-arrow-color: #596975;
  /* FireFox */
  scrollbar-color: #596975 rgba(217, 228, 235, 0.75);
  scrollbar-width: thin;
}

/* Chrome */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
*::-webkit-scrollbar-thumb {
  background: #969CA4;
  border-radius: 3px;
  height: 2px;
}
*::-webkit-scrollbar-track {
  background: unset;
}
